<template>
    <div v-if="template !== undefined && categories !== undefined">
        <div v-if="pdf === 'error'">Failure to load pdf. Invalid characters inputted.</div>
        <embed v-else id="pdfId" :src='pdf' type="application/pdf" width="1000" height="1100"/>
    </div>
</template>

<script>
import { PDFDocument, StandardFonts, rgb} from 'pdf-lib'
import report from "@/services/report";
import PubSub from 'pubsub-js';

export default {
    name: "PdfPreview",
    props: {
        template: Array,
        categories: Array,
        isTemplate: {
            type: Boolean,
            default: false,
        },
        user: String,
        caseId: String,
        reportDate: String
    },
    data() {
        return {
            pdf: null,
            pdfDoc: null,
            pdfHeader: '',
            pdfDownload: '',
        }
    },
    created() {
        if (this.isTemplate === false) {
            report.getCasePdf(this.caseId).then(response => {
                this.pdf = response.data.preview;
                this.pdfDownload = response.data.download;
            })
        } else {
            //size limit of image is about 1.5MB
            report.getPdfHeader('FileHeader.png').then(response => {
                this.pdfHeader = response.data;
                this.pdfSetUp();
            })
        }
        PubSub.subscribe('downloadPDFReport', () => {
            this.$emit('createPdfDownloadLink', this.pdfDownload)
        })
    },
    methods: {
        async pdfSetUp() {
            try {
                // Create a new PDFDocument
                this.pdfDoc = await PDFDocument.create();

                let page = this.pdfDoc.addPage();

                //determine page size for reference
                const timesRomanFont = await this.pdfDoc.embedFont(StandardFonts.Helvetica);
                const timesRomanFontBold = await this.pdfDoc.embedFont(StandardFonts.HelveticaBold);
                const {width, height} = page.getSize();

                const fontSizeCategory = 15;
                const fontSizeItem = 12;
                const fontSizeField = 9;

                const titleIncrement = 30;
                const itemIncrement = 18;
                const fieldIncrement = 28;
                const newTitleIncrement = 10;
                const newLineIncrement = 10;
                //amount of space at the top of the page before the first category
                const pageInsert = 110;
                //how far down the page we are
                let pageIncrement = 0;
                //how far horizontally we are before we need to go down farther
                let svgPath;
                //used for resizing an image
                let pdfHeader, pdfScale;

                const winCount = '650 W. State Street Rockford, IL 61102';
                if (this.template !== undefined) {

                    pageIncrement += pageInsert;
                    //create the pdf header
                    svgPath = 'M ' + width * .05 + ',0 ' +
                        'L ' + width * .95 + ',0 ' +
                        'L ' + width * .95 + ',' + height * .035 + ' ' +
                        'L ' + width * .05 + ',' + height * .035 + ' ' +
                        'L ' + width * .05 + ',0';
                    page.moveTo(0, height - 77);
                    page.drawSvgPath(svgPath, {color: rgb(.18, .18, .18)});
                    if (this.reportNumber !== undefined) {
                        page.drawText("Report " + this.reportNumber, {
                            x: (width / 2) - (timesRomanFontBold.widthOfTextAtSize("Report " + this.reportNumber, 20) / 2),
                            y: height - 98,
                            font: timesRomanFontBold,
                            size: 20,
                            color: rgb(1, 1, 1)
                        });
                    }
                    page.drawText("Winnebago County Police Department", {
                        x: 103,
                        y: height - 55,
                        font: timesRomanFontBold,
                        size: 20,
                    });
                    page.drawText(winCount, {
                        x: 104,
                        y: height - 70,
                        font: timesRomanFont,
                        size: fontSizeItem,
                    });
                    if (this.reportDate !== undefined) {
                        page.drawText("Report Date:"+this.reportDate, {
                            x: width * .05,
                            y: height - 117,
                            font: timesRomanFont,
                            size: fontSizeField,
                        });
                    }
                    if (this.user !== undefined) {
                        page.drawText('Report By: '+this.user, {
                            x: (width * .95) - timesRomanFont.widthOfTextAtSize('Report By: '+this.user, fontSizeField),
                            y: height - 117,
                            font: timesRomanFont,
                            size: fontSizeField,
                        });
                    }

                    //add image inside the pdf header
                    try {
                        pdfHeader = await this.pdfDoc.embedJpg(this.pdfHeader);
                        pdfScale = pdfHeader.scale(75 / pdfHeader.height);
                    } catch (e) {
                        try {
                            pdfHeader = await this.pdfDoc.embedPng(this.pdfHeader);
                            pdfScale = pdfHeader.scale(75 / pdfHeader.height);
                        } catch (e) {
                            //no image header
                        }
                    }
                    //If the photo is very width heavy then we scale it to the width
                    if (pdfScale.width > 160) {
                        pdfScale = pdfHeader.scale(160 / pdfHeader.width);
                    }
                    page.drawImage(pdfHeader, {
                        x: width * .053,
                        y: height * .87225,
                        width: pdfScale.width,
                        height: pdfScale.height,
                    });
                }
                if (this.isTemplate === false) {
                    await this.createPdf(page, timesRomanFont, timesRomanFontBold, width, height, fontSizeCategory, fontSizeItem, fontSizeField,
                        titleIncrement, itemIncrement, fieldIncrement, newTitleIncrement, newLineIncrement, pageInsert);
                } else {
                    await this.previewPdf(page, timesRomanFont, timesRomanFontBold, width, height, fontSizeCategory, fontSizeItem, fontSizeField,
                        titleIncrement, itemIncrement, fieldIncrement, newTitleIncrement, newLineIncrement, pageInsert);
                }
            } catch (e) {
                console.error(e);
            }
        },
        async createPdf(page, timesRomanFont, timesRomanFontBold, width, height, fontSizeCategory, fontSizeItem, fontSizeField,
                        titleIncrement, itemIncrement, fieldIncrement, newTitleIncrement, newLineIncrement, pageInsert) {
            try {
                //how far down the page we are
                let pageIncrement = 0;
                //how far horizontally we are before we need to go down farther
                let newRow = 0;
                let svgPath;
                if (this.template !== undefined) {
                    pageIncrement += pageInsert;
                    //Loop categories
                    this.template.forEach((category, i) => {
                        //these two line make sure it goes to the next line and start at the far left
                        newRow = 0;
                        pageIncrement += titleIncrement;
                        //if not enough space on current page, go to next page
                        if (pageIncrement + 30 > height) {
                            page = this.pdfDoc.addPage();
                            pageIncrement = titleIncrement;
                        }
                        //draw boarder around category
                        svgPath = 'M ' + width * .05 + ',0 ' +
                            'L ' + width * .95 + ',0 ' +
                            'M ' + width * .95 + ',' + (fontSizeCategory + 5) + ' ' +
                            'L ' + width * .05 + ',' + (fontSizeCategory + 5);
                        page.moveTo(0, height - pageIncrement + fontSizeCategory);
                        page.drawSvgPath(svgPath, {borderWidth: 2});
                        //write category title
                        page.drawText(category.name, {
                            x: (width * .5)-(timesRomanFontBold.widthOfTextAtSize(category.name, fontSizeCategory) / 2),
                            y: height - pageIncrement,
                            font: timesRomanFontBold,
                            size: fontSizeCategory,
                        });
                        //Loop items
                        this.categories[i].items.forEach((item, j) => {
                            //if not enough space on current page, go to next page
                            if (pageIncrement + 30 > height) {
                                page = this.pdfDoc.addPage();
                                pageIncrement = titleIncrement;
                            }
                            //these two line make sure it goes to the next line and start at the far left
                            newRow = 0;
                            pageIncrement += itemIncrement;
                            //write item title
                            page.drawText(category.item.name + ' ' + (j + 1).toString(), {
                                x: width * .05,
                                y: height - pageIncrement,
                                font: timesRomanFontBold,
                                size: fontSizeItem,
                            });
                            //underline all categories
                            svgPath = 'M 0,3 L ' + timesRomanFontBold.widthOfTextAtSize(category.item.name + ' ' + (j+1).toString(), fontSizeItem) + ',3';
                            page.moveTo((width * .05), height - pageIncrement);
                            page.drawSvgPath(svgPath, {borderWidth: 2});

                            pageIncrement += itemIncrement;

                            //Loop fields
                            category.item.fields.forEach((field, k) => {
                                //make sure the field size doesn't need more space that we have
                                //ex. if newRow = 3 and field.size = 12 we need to go to the next row
                                //since each row can only have 12
                                if (newRow + field.size > 12) {
                                    pageIncrement += fieldIncrement;
                                    newRow = 0;
                                }
                                //if not enough space on current page, go to next page
                                if (pageIncrement+fieldIncrement+30 > height) {
                                    page = this.pdfDoc.addPage();
                                    pageIncrement = titleIncrement;
                                }
                                //address field needs a special check for space since it is done differently
                                if (pageIncrement + 65 > height && field.type === 'address') {
                                    page = this.pdfDoc.addPage();
                                    pageIncrement = titleIncrement;
                                }
                                //write field name
                                if (field.type !== 'inmate' && field.type !== 'officer' && field.type !== 'spacer') {
                                    page.drawText(field.name, {
                                        x: width * .05 + (width * .225) * (newRow / 3) + 5,
                                        y: height - pageIncrement,
                                        font: timesRomanFontBold,
                                        size: fontSizeField,
                                    });
                                }
                                if (field.type === 'address') {
                                    if (item[field._id] !== undefined) {
                                        page.drawText('Address: ', {
                                            x: width * .05 + 10,
                                            y: height - pageIncrement - 12,
                                            font: timesRomanFont,
                                            size: fontSizeField,
                                        });
                                        page.drawText(item[field._id].address.toString(), {
                                            x: width * .05 + 15,
                                            y: height - pageIncrement - 24,
                                            font: timesRomanFont,
                                            size: fontSizeField,
                                        });
                                        page.drawText('Apartment#: ', {
                                            x: width * .45 + 10,
                                            y: height - pageIncrement - 12,
                                            font: timesRomanFont,
                                            size: fontSizeField,
                                        });
                                        page.drawText(item[field._id].apartment.toString(), {
                                            x: width * .45 + 15,
                                            y: height - pageIncrement - 24,
                                            font: timesRomanFont,
                                            size: fontSizeField,
                                        });
                                        pageIncrement += 25;
                                        page.drawText('City: ', {
                                            x: width * .05 + 10,
                                            y: height - pageIncrement - 12,
                                            font: timesRomanFont,
                                            size: fontSizeField,
                                        });
                                        page.drawText(item[field._id].city.toString(), {
                                            x: width * .05 + 15,
                                            y: height - pageIncrement - 24,
                                            font: timesRomanFont,
                                            size: fontSizeField,
                                        });
                                        page.drawText('State: ', {
                                            x: width * .30 + 10,
                                            y: height - pageIncrement - 12,
                                            font: timesRomanFont,
                                            size: fontSizeField,
                                        });
                                        page.drawText(item[field._id].state.toString(), {
                                            x: width * .30 + 15,
                                            y: height - pageIncrement - 24,
                                            font: timesRomanFont,
                                            size: fontSizeField,
                                        });
                                        page.drawText('Zip: ', {
                                            x: width * .45 + 10,
                                            y: height - pageIncrement - 12,
                                            font: timesRomanFont,
                                            size: fontSizeField,
                                        });
                                        page.drawText(item[field._id].zip.toString(), {
                                            x: width * .45 + 15,
                                            y: height - pageIncrement - 24,
                                            font: timesRomanFont,
                                            size: fontSizeField,
                                        });
                                        pageIncrement += 10;
                                    }
                                } else if (field.type === 'inmate') {
                                    //leave empty this field in only used to search for inmates
                                    pageIncrement -= fieldIncrement;
                                } else if (field.type === 'officer') {
                                    //leave empty this field in only used to search for officers
                                    pageIncrement -= fieldIncrement;
                                } else if (field.type === 'richTextBox') {
                                    let splitArray = item[field._id].toString().split(' ');
                                    let stringArray = [];
                                    let currentString = '';
                                    for (let l = 0; l < splitArray.length; l++) {
                                        if (timesRomanFont.widthOfTextAtSize(currentString, fontSizeField)+timesRomanFont.widthOfTextAtSize(splitArray[l], fontSizeField) < width*.85) {
                                            currentString += splitArray[l]+' ';
                                        } else if (timesRomanFont.widthOfTextAtSize(splitArray[l], fontSizeField) > width*.85) {
                                            stringArray.push(currentString);
                                            currentString = '';
                                            for (let n = 0; n < splitArray[l].length; n++) {
                                                if (timesRomanFont.widthOfTextAtSize(currentString, fontSizeField)+timesRomanFont.widthOfTextAtSize(splitArray[l][n], fontSizeField) < width*.85) {
                                                    currentString += splitArray[l][n];
                                                } else {
                                                    currentString += '-';
                                                    stringArray.push(currentString);
                                                    currentString = '';
                                                }
                                            }
                                            currentString += ' ';
                                        } else {
                                            stringArray.push(currentString);
                                            currentString = splitArray[l] + ' ';
                                        }
                                    }
                                    if(currentString !== '') {
                                        stringArray.push(currentString);
                                    }
                                    stringArray.forEach((text, o) => {
                                        if(o*newLineIncrement+pageIncrement+15 > height) {
                                            page = this.pdfDoc.addPage();
                                            pageIncrement = itemIncrement;
                                        }
                                        page.drawText(text, {
                                            x: width * .05 + (width * .225) * (newRow / 3) + 10,
                                            y: height - pageIncrement - 12,
                                            font: timesRomanFont,
                                            size: fontSizeField,
                                        });
                                        pageIncrement += newLineIncrement;
                                    })
                                    pageIncrement -= newLineIncrement;
                                } else {
                                    //Loop field info
                                    if (item[field._id] !== undefined) {
                                        page.drawText(item[field._id].toString(), {
                                            x: width * .05 + (width * .225) * (newRow / 3) + 10,
                                            y: height - pageIncrement - 12,
                                            font: timesRomanFont,
                                            size: fontSizeField,
                                        });
                                    }
                                }
                                newRow += this.template[i].item.fields[k].size;
                            })
                            pageIncrement += newTitleIncrement;
                        })
                    })
                    for (let i = 1; i <= this.pdfDoc.getPageCount(); i++) {
                        let footer = 'Page ' + i + ' of ' + this.pdfDoc.getPageCount();
                        page = (this.pdfDoc.getPages())[i-1];
                        page.drawText(footer, {
                            x: (width / 2) - (timesRomanFont.widthOfTextAtSize(footer, fontSizeField) / 2),
                            y: 15,
                            font: timesRomanFont,
                            size: fontSizeField
                        });
                    }
                    // Load a PDF with form fields
                    let base64 = await this.pdfDoc.saveAsBase64();
                    this.pdfDownload = await this.pdfDoc.save();
                    this.pdf = 'data:application/pdf;base64,' + base64;
                }
            } catch (e) {
                this.pdf = 'error';
            }
        },
        async previewPdf(page, timesRomanFont, timesRomanFontBold, width, height, fontSizeCategory, fontSizeItem, fontSizeField,
                         titleIncrement, itemIncrement, fieldIncrement, newTitleIncrement, newLineIncrement, pageInsert) {
            try {
                //how far down the page we are
                let pageIncrement = 0;
                //how far horizontally we are before we need to go down farther
                let newRow = 0;
                let svgPath;
                if (this.template !== undefined) {
                    pageIncrement += pageInsert;
                    //Loop categories
                    this.template.forEach((category, i) => {
                        //these two line make sure it goes to the next line and start at the far left
                        newRow = 0;
                        pageIncrement += titleIncrement;
                        //if not enough space on current page, go to next page
                        if (pageIncrement + 15 > height) {
                            page = this.pdfDoc.addPage();
                            pageIncrement = titleIncrement;
                        }
                        //draw boarder around category
                        svgPath = 'M ' + width * .05 + ',0 ' +
                            'L ' + width * .95 + ',0 ' +
                            'M ' + width * .95 + ',' + (fontSizeCategory + 5) + ' ' +
                            'L ' + width * .05 + ',' + (fontSizeCategory + 5);
                        page.moveTo(0, height - pageIncrement + fontSizeCategory);
                        page.drawSvgPath(svgPath, {borderWidth: 2});
                        //write category title
                        page.drawText(category.name, {
                            x: (width * .5)-(timesRomanFontBold.widthOfTextAtSize(category.name, fontSizeCategory) / 2),
                            y: height - pageIncrement,
                            font: timesRomanFontBold,
                            size: fontSizeCategory,
                        });
                        //if not enough space on current page, go to next page
                        if (pageIncrement + 15 > height) {
                            page = this.pdfDoc.addPage();
                            pageIncrement = titleIncrement;
                        }
                        //these two line make sure it goes to the next line and start at the far left
                        newRow = 0;
                        pageIncrement += itemIncrement;
                        //write item title
                        page.drawText(category.item.name.toString(), {
                            x: width * .05,
                            y: height - pageIncrement,
                            font: timesRomanFontBold,
                            size: fontSizeItem,
                        });
                        //underline all categories
                        svgPath = 'M 0,3 L ' + timesRomanFontBold.widthOfTextAtSize(category.item.name.toString(), fontSizeItem) + ',3';
                        page.moveTo((width * .05), height - pageIncrement);
                        page.drawSvgPath(svgPath, {borderWidth: 2});
                        pageIncrement += itemIncrement;
                        //Loop fields
                        category.item.fields.forEach((field, k) => {
                            //make sure the field size doesn't need more space that we have
                            //ex. if newRow = 3 and field.size = 12 we need to go to the next row
                            //since each row can only have 12
                            if (newRow + field.size > 12) {
                                pageIncrement += fieldIncrement;
                                newRow = 0;
                            }
                            //if not enough space on current page, go to next page
                            if (pageIncrement+fieldIncrement+15 > height) {
                                page = this.pdfDoc.addPage();
                                pageIncrement = titleIncrement;
                            }
                            //address field needs a special check for space since it is done differently
                            if (pageIncrement + 50 > height && field.type === 'address') {
                                page = this.pdfDoc.addPage();
                                pageIncrement = titleIncrement;
                            }
                            //write field name
                            if (field.type !== 'inmate' && field.type !== 'officer' && field.type !== 'spacer') {
                                page.drawText(field.name, {
                                    x: width * .05 + (width * .225) * (newRow / 3) + 5,
                                    y: height - pageIncrement,
                                    font: timesRomanFontBold,
                                    size: fontSizeField,
                                });
                            } else if (field.type !== 'spacer') {
                                pageIncrement -= 26;
                            }
                            newRow += this.template[i].item.fields[k].size;
                        })
                        pageIncrement += newTitleIncrement;
                    })
                    for (let i = 1; i <= this.pdfDoc.getPageCount(); i++) {
                        let footer = 'Page ' + i + ' of ' + this.pdfDoc.getPageCount();
                        page = (this.pdfDoc.getPages())[i-1];
                        page.drawText(footer, {
                            x: (width / 2) - (timesRomanFont.widthOfTextAtSize(footer, fontSizeField) / 2),
                            y: 15,
                            font: timesRomanFont,
                            size: fontSizeField
                        });
                    }
                    // Load a PDF with form fields
                    let base64 = await this.pdfDoc.saveAsBase64();
                    this.pdfDownload = base64;
                    this.pdf = 'data:application/pdf;base64,' + base64;
                }
            } catch (e) {
                this.pdf = 'error';
                console.error(e);
            }
        }
    }
}
</script>

<style>

</style>
